.MuiDataGrid-virtualScroller {
  overflow-y: hidden !important;
}
.MuiDataGrid-root {
  border: 0 !important;
}
.MuiTabPanel-root {
  height: 100vh !important;
}

.image-modal > .MuiButtonBase-root:hover {
  background-color: #fff !important;
}
.MuiPaper-root {
  box-shadow: none !important;
}

.switch-button {
  background: red;
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #155fff;
  position: relative;
  padding-right: 120px;
  position: relative;
}
.switch-button:before {
  content: "INACTIVE";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
/* car card */

/* .overlay {
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background-color: rgb(227, 227, 227);
  cursor: pointer;
} */
/* .image-edit {
  position: absolute;
  top: 15%;
  left: 90%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
} */
.card-content:hover {
  opacity: 0.7;
  cursor: pointer;
}
/* .image-edit .MuiButton-root {
  background-color: rgba(195, 108, 255, 0.52);
  color: black;
  border-radius: 50%;
  min-width: 30px;
} */
/* .image-edit .MuiButton-root:hover {
  background-color: rgb(128, 75, 223);
  color: #fff;
  border-radius: 50%;
  min-width: 30px;
} */

/* menu button */
.menu-item .Mui-selected {
  border-radius: 0px 100px 100px 0px !important;
  background: rgb(255, 234, 168);
  background: linear-gradient(
    94deg,
    rgba(255, 234, 168, 1) 0%,
    rgba(255, 199, 23, 1) 100%
  ) !important;
  box-shadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px " !important;
  color: #fff;
}
.menu-item .MuiListItemButton-root:hover {
  border-radius: 0px 100px 100px 0px !important;
}
.menu-item .MuiListItemButton-root {
  margin: 10px 0;
}
/* header search*/
.nav-header {
  position: sticky;
  top: 0;
  transition: background-color 0.4s ease-out;
  z-index: 9;
}
.search .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.nav-header.scroll {
  background-color: red;
  color: #eef;
}

/* Typography */

.MuiTypography-root {
  font-family: Inter, sans-serif;
  font-weight: "500" !important;
}

/* Header Search */
.nav-header .MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
}
.status-select .MuiInputBase-root {
  height: 40px !important;
}
/* car brand */
.car-brand {
  margin-top: 10px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 500;
  font-size: 0.875rem;
}
/* car model name */
.car-model {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
}

/* price */
.car-price {
  margin: 0px 0.25rem 0px 0px;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  font-weight: 600;
  color: rgb(255, 199, 23);
}
/* profile */
/* .header-name {
  display: flex;
  margin-left: 0.75rem;
  align-items: flex-start;
  flex-direction: column;
} */
.profile-name {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 600;
}
.profile-role {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.38);
  font-size: 0.8rem;
}
.profile-list {
  background-color: transparent;
  outline: currentcolor none 0px;
  border: 0px none;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  display: flex;
  -moz-box-pack: start;
  justify-content: flex-start;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 0rem;
}
.profile-menu {
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  color: rgba(58, 53, 65, 0.87);
  text-decoration: none;
}
.profile-avatar {
  position: relative;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  width: 2.5rem;
  height: 2.5rem;
}

/* */
.menu-subheader {
  margin: 0px;
  font-family: Inter, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.21px;
  text-transform: uppercase;
  color: rgba(58, 53, 65, 0.38);
  font-weight: 500;
}

/* tinymce title */
.tinymce-title {
  /* margin: 0px 0px -9px 0.125rem; */
  margin-top: 20px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 600;
  font-size: 1rem;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-table {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.15px !important;
  color: rgba(58, 53, 65, 0.87) !important;
}
.ant-modal-header {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.15px !important;
  color: rgba(58, 53, 65, 0.87) !important;
}

/* @media screen and (min-width: 1400px) and (max-width: 1800px) {
  .card-body {
    flex: 0 0 33.33% !important ;
  }
} */
.send_reply .MuiOutlinedInput-input {
  height: 10px !important;
}
.send_reply .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Toastify__toast-container {
  z-index: 9999 !important; 
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .card-body {
    flex-basis: 32% !important ;
    flex-grow: 0;
  }
  .offer-body {
    flex-basis: 48% !important ;
    flex-grow: 0;
  }
  .selected-cars {
    flex-basis: 30% !important ;
    flex-grow: 0;
  }
}
/* @media screen and (min-width: 1200px) {
  .card-body {
    flex-basis:24.33% !important ;
    flex-grow: 0;
  
  }
} */
@media screen and (min-width: 850px) and (max-width: 1200px) {
  .card-body {
    flex-basis: 32% !important ;
    flex-grow: 0;
    /* min-width: 250px; */
  }
  /* .offer-modal {
    min-width: 100 !important;
  } */
  .offer-modal {
    min-width: 700px !important;
  }
  .enquiry-card {
    flex: 1 !important;
  }
  .enquiry-chat {
    flex: 1 !important;
  }
  .selected-cars {
    flex-basis: 45% !important ;
    flex-grow: 0;
  }
  .user-card {
    flex-basis: 48% !important ;
    flex-grow: 0;
  }
  .enquiry_card {
    flex-basis: 45% !important ;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .card-body {
    flex-basis: 32% !important ;
    flex-grow: 0;
    /* min-width: 250px; */
  }
  .offer-body {
    flex-basis: 30% !important ;
    flex-grow: 0;
  }
  .enquiry-card {
    flex: 2 !important;
  }
  .enquiry-chat {
    flex: 3 !important;
  }
  /* .offer-modal {
    min-width: 700px !important;
  } */
}
@media screen and (max-width: 850px) {
  .card-body {
    flex-basis: 99% !important ;
    flex-grow: 0;
  }
  /* .offer-modal {
    min-width: 500px !important;
  } */
  .offer-body {
    flex-basis: 95% !important ;
    flex-grow: 0;
  }
  .user-card {
    flex-basis: 99% !important ;
    flex-grow: 0;
  }
  .customer-card {
    flex-basis: 99% !important ;
    flex-grow: 0;
  }
  .enquiry_card {
    flex-basis: 95% !important ;
    flex-grow: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1570px) {
  .user-card {
    flex-basis: 32% !important ;
    flex-grow: 0;
  }
  .enquiry_card {
    flex-basis: 30% !important ;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1730px) {
  .customer-card {
    flex-basis: 32% !important ;
    flex-grow: 0;
  }
}
@media screen and (min-width: 900px) and (max-width: 1300px) {
  .customer-card {
    flex-basis: 48% !important ;
    flex-grow: 0;
  }
}

#datePicl-label + .MuiInputBase-root > fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

#datePicl-label {
  color: rgba(0, 0, 0, 0.6);
}

.image-delete {
  display: none;
}

#image-container:hover .image-delete {
  display: block;
}
